import React from "react";
import { FaCalendar, FaUsers } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const QuickLinks: React.FC = () => {
  return (
    <div className="bg-secondary-black py-8 font-mono">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <a
            href="https://lu.ma/hbfv6c2m?tk=UewkRZ"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center p-4 bg-primary-black text-accent-green rounded-lg border border-accent-green hover:bg-secondary-black transition duration-300"
          >
            <FaCalendar className="text-accent-green text-xl md:text-2xl mr-2" />
            <span className="font-semibold text-sm md:text-base">
              Register_on_Luma
            </span>
          </a>
          <a
            href="https://www.meetup.com/hack-ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center p-4 bg-primary-black text-accent-green rounded-lg border border-accent-green hover:bg-secondary-black transition duration-300"
          >
            <FaUsers className="text-accent-green text-xl md:text-2xl mr-2" />
            <span className="font-semibold text-sm md:text-base">
              Join_our_Meetup
            </span>
          </a>
          <a
            href="https://x.com/i/communities/1813691395217219590"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center p-4 bg-primary-black text-accent-green rounded-lg border border-accent-green hover:bg-secondary-black transition duration-300 sm:col-span-2 md:col-span-1"
          >
            <FaXTwitter className="text-accent-green text-xl md:text-2xl mr-2" />
            <span className="font-semibold text-sm md:text-base">
              Join_our_X_community
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
