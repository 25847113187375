import React from "react";
import PastEvents from "./PastEvents";
import UpcomingEvents from "./UpcomingEvents";

const EventsPage: React.FC = () => {
  return (
    <div className="bg-primary-black text-accent-green min-h-screen px-4 py-24 font-mono">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold mb-12 text-center glow-green-sm">
          HackAI_Events
        </h1>
        <UpcomingEvents />
        <PastEvents />
      </div>
    </div>
  );
};

export default EventsPage;
