import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { FaSort, FaChevronDown, FaChevronUp } from "react-icons/fa";

interface Project {
  id: string;
  teamName: string;
  projectName: string;
  description: string;
  techStack: string[];
  githubLink: string;
  demoLink: string;
  teamMembers: string[];
  challenges: string;
  futureImprovements: string;
  submittedAt: Date;
  hackathonName: string;
}

const ProjectList: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [sortField, setSortField] = useState<keyof Project>("submittedAt");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [hackathons, setHackathons] = useState<string[]>([]);
  const [selectedHackathon, setSelectedHackathon] = useState<string>("All");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsCollection = collection(db, "projectSubmissions");
        const projectSnapshot = await getDocs(projectsCollection);
        const projectList = projectSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          submittedAt: doc.data().submittedAt.toDate(),
        })) as Project[];
        setProjects(projectList);

        // Extract unique hackathon names
        const uniqueHackathons = Array.from(
          new Set(projectList.map((p) => p.hackathonName))
        );
        setHackathons(["All", ...uniqueHackathons]);
      } catch (error) {
        console.error("Error fetching projects: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleSort = (field: keyof Project) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedProjects = projects
    .filter(
      (project) =>
        selectedHackathon === "All" ||
        project.hackathonName === selectedHackathon
    )
    .sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

  if (loading) {
    return (
      <div className="text-center mt-8 text-gray-800 dark:text-gray-200">
        Loading projects...
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-8 px-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
        Submitted Projects (Total: {sortedProjects.length})
      </h2>
      <div className="mb-4">
        <label
          htmlFor="hackathonSelect"
          className="mr-2 text-gray-800 dark:text-white"
        >
          Hackathon:
        </label>
        <select
          id="hackathonSelect"
          value={selectedHackathon}
          onChange={(e) => setSelectedHackathon(e.target.value)}
          className="p-2 rounded bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
        >
          {hackathons.map((hackathon) => (
            <option key={hackathon} value={hackathon}>
              {hackathon}
            </option>
          ))}
        </select>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200 dark:bg-gray-700">
            <tr>
              {["teamName", "projectName", "hackathonName", "submittedAt"].map(
                (field) => (
                  <th
                    key={field}
                    className="px-4 py-2 text-left text-gray-800 dark:text-white cursor-pointer"
                    onClick={() => handleSort(field as keyof Project)}
                  >
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                    {sortField === field &&
                      (sortDirection === "asc" ? (
                        <FaChevronUp className="inline ml-1" />
                      ) : (
                        <FaChevronDown className="inline ml-1" />
                      ))}
                    {sortField !== field && <FaSort className="inline ml-1" />}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {sortedProjects.map((project) => (
              <tr
                key={project.id}
                className="border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                onClick={() => setSelectedProject(project)}
              >
                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">
                  {project.teamName}
                </td>
                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">
                  {project.projectName}
                </td>
                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">
                  {project.hackathonName}
                </td>
                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">
                  {project.submittedAt.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedProject && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          onClick={() => setSelectedProject(null)}
        >
          <div
            className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white dark:bg-gray-800"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-bold mb-4 text-gray-800 dark:text-white">
              {selectedProject.projectName} by {selectedProject.teamName}
            </h3>
            <p className="mb-2 text-gray-600 dark:text-gray-300">
              <strong>Description:</strong> {selectedProject.description}
            </p>
            <p className="mb-2 text-gray-600 dark:text-gray-300">
              <strong>Tech Stack:</strong>{" "}
              {selectedProject.techStack.join(", ")}
            </p>
            <p className="mb-2 text-gray-600 dark:text-gray-300">
              <strong>GitHub:</strong>{" "}
              <a
                href={selectedProject.githubLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {selectedProject.githubLink}
              </a>
            </p>
            <p className="mb-2 text-gray-600 dark:text-gray-300">
              <strong>Demo:</strong>{" "}
              <a
                href={selectedProject.demoLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {selectedProject.demoLink}
              </a>
            </p>
            <p className="mb-2 text-gray-600 dark:text-gray-300">
              <strong>Team Members:</strong>{" "}
              {selectedProject.teamMembers.join(", ")}
            </p>
            <p className="mb-2 text-gray-600 dark:text-gray-300">
              <strong>Challenges:</strong> {selectedProject.challenges}
            </p>
            <p className="mb-2 text-gray-600 dark:text-gray-300">
              <strong>Future Improvements:</strong>{" "}
              {selectedProject.futureImprovements}
            </p>
            <p className="mb-2 text-gray-600 dark:text-gray-300">
              <strong>Hackathon:</strong> {selectedProject.hackathonName}
            </p>
            <p className="text-gray-600 dark:text-gray-300">
              <strong>Submitted At:</strong>{" "}
              {selectedProject.submittedAt.toLocaleString()}
            </p>
            <button
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => setSelectedProject(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectList;
