import React from "react";
import { FaHardHat, FaTools } from "react-icons/fa";

const UnderConstruction: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-800">
      <div className="text-6xl mb-4 text-yellow-500">
        <FaHardHat className="inline-block mr-2" />
        <FaTools className="inline-block" />
      </div>
      <h1 className="text-3xl font-bold mb-2 text-gray-800 dark:text-white">
        Under Construction
      </h1>
      <p className="text-xl text-gray-600 dark:text-gray-300">
        This part is under construction for now. Please check back later!
      </p>
    </div>
  );
};

export default UnderConstruction;
