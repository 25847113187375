import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

interface EmailSubmission {
  id: string;
  email: string;
  submittedAt: Date;
}

const EmailList: React.FC = () => {
  const [emails, setEmails] = useState<EmailSubmission[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const emailsCollection = collection(db, "emails");
        const emailSnapshot = await getDocs(emailsCollection);
        const emailList = emailSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          submittedAt: doc.data().submittedAt.toDate(),
        })) as EmailSubmission[];
        setEmails(emailList);
      } catch (error) {
        console.error("Error fetching emails: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmails();
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-8 text-gray-800 dark:text-gray-200">
        Loading emails...
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-8 px-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
        Email Submissions
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200 dark:bg-gray-700">
            <tr>
              <th className="px-4 py-2 text-left text-gray-800 dark:text-white">
                Email
              </th>
              <th className="px-4 py-2 text-left text-gray-800 dark:text-white">
                Submitted At
              </th>
            </tr>
          </thead>
          <tbody>
            {emails.map((submission) => (
              <tr key={submission.id} className="border-b dark:border-gray-700">
                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">
                  {submission.email}
                </td>
                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">
                  {submission.submittedAt.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmailList;
