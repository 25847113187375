import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";
import { FaGithub, FaYoutube, FaPaperPlane, FaUpload } from "react-icons/fa";

interface ProjectSubmission {
  projectName: string;
  description: string;
  techStack: string[];
  githubLink: string;
  demoLink: string;
  teamMembers: string[];
  challenges: string;
  futureImprovements: string;
  fileUrl: string;
}

const SubmitProject: React.FC = () => {
  const [formData, setFormData] = useState<ProjectSubmission>({
    projectName: "",
    description: "",
    techStack: [],
    githubLink: "",
    demoLink: "",
    teamMembers: [""],
    challenges: "",
    futureImprovements: "",
    fileUrl: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [uploadType, setUploadType] = useState<"github" | "file">("github");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTechStackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const techs = e.target.value.split(",").map((tech) => tech.trim());
    setFormData((prev) => ({ ...prev, techStack: techs }));
  };

  const handleTeamMemberChange = (index: number, value: string) => {
    const newTeamMembers = [...formData.teamMembers];
    newTeamMembers[index] = value;
    setFormData((prev) => ({ ...prev, teamMembers: newTeamMembers }));
  };

  const addTeamMember = () => {
    setFormData((prev) => ({
      ...prev,
      teamMembers: [...prev.teamMembers, ""],
    }));
  };

  const removeTeamMember = (index: number) => {
    const newTeamMembers = formData.teamMembers.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, teamMembers: newTeamMembers }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (selectedFile.size > 512 * 1024 * 1024) {
        setError("File size exceeds 512 MB limit.");
        return;
      }
      setFile(selectedFile);
    }
  };

  const uploadFile = async () => {
    if (!file) return null;
    const fileRef = ref(
      storage,
      `project-files/${formData.projectName}/${file.name}`
    );
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      let fileUrl = "";
      if (uploadType === "file" && file) {
        fileUrl = (await uploadFile()) || "";
      }

      const submissionData = {
        ...formData,
        fileUrl,
        hackathonName: "August 2024 Brev Llama3.1",
        submittedAt: new Date(),
      };

      const docRef = await addDoc(
        collection(db, "projectSubmissions"),
        submissionData
      );
      console.log("Document written with ID: ", docRef.id);
      setSubmitted(true);
    } catch (e) {
      console.error("Error adding document: ", e);
      setError(
        "An error occurred while submitting your project. Please try again."
      );
    }
  };

  if (submitted) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-800 flex items-center justify-center">
        <div className="bg-white dark:bg-gray-700 p-8 rounded-lg shadow-md text-center">
          <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
            Thank You for Your Submission!
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            Your project has been successfully submitted. Good luck!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-extrabold text-center text-gray-900 dark:text-white mt-12 mb-8">
          Submit Your Project
        </h1>
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          className="bg-white dark:bg-gray-700 shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              htmlFor="projectName"
            >
              Project Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-600 dark:text-white"
              id="projectName"
              type="text"
              name="projectName"
              value={formData.projectName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              htmlFor="description"
            >
              Project Description
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-600 dark:text-white"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows={4}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              htmlFor="techStack"
            >
              Tech Stack (comma-separated, optional)
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-600 dark:text-white"
              id="techStack"
              type="text"
              name="techStack"
              value={formData.techStack.join(", ")}
              onChange={handleTechStackChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
              Project Submission
            </label>
            <div className="flex mb-2">
              <button
                type="button"
                onClick={() => setUploadType("github")}
                className={`mr-2 px-4 py-2 rounded ${
                  uploadType === "github"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                GitHub Repository
              </button>
              <button
                type="button"
                onClick={() => setUploadType("file")}
                className={`px-4 py-2 rounded ${
                  uploadType === "file"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                File Upload
              </button>
            </div>
            {uploadType === "github" ? (
              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  <FaGithub />
                </span>
                <input
                  className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="githubLink"
                  type="url"
                  name="githubLink"
                  value={formData.githubLink}
                  onChange={handleInputChange}
                  required={uploadType === "github"}
                />
              </div>
            ) : (
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="fileUpload"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <FaUpload className="w-10 h-10 mb-3 text-gray-400" />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      Any file type (MAX. 512MB)
                    </p>
                  </div>
                  <input
                    id="fileUpload"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    required={uploadType === "file"}
                  />
                </label>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              htmlFor="demoLink"
            >
              Demo Link (YouTube, website, etc.) (optional)
            </label>
            <div className="flex">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <FaYoutube />
              </span>
              <input
                className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="demoLink"
                type="url"
                name="demoLink"
                value={formData.demoLink}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
              Team Members
            </label>
            {formData.teamMembers.map((member, index) => (
              <div key={index} className="flex mb-2">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-600 dark:text-white"
                  type="text"
                  value={member}
                  onChange={(e) =>
                    handleTeamMemberChange(index, e.target.value)
                  }
                  placeholder={`Team member ${index + 1}`}
                  required
                />
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeTeamMember(index)}
                    className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={addTeamMember}
              className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Add Team Member
            </button>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              htmlFor="challenges"
            >
              Challenges Faced
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-600 dark:text-white"
              id="challenges"
              name="challenges"
              value={formData.challenges}
              onChange={handleInputChange}
              rows={3}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              htmlFor="futureImprovements"
            >
              Future Improvements
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-600 dark:text-white"
              id="futureImprovements"
              name="futureImprovements"
              value={formData.futureImprovements}
              onChange={handleInputChange}
              rows={3}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
              type="submit"
            >
              <FaPaperPlane className="mr-2" />
              Submit Project
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubmitProject;
