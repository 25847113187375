import React from "react";
import { FaXTwitter } from "react-icons/fa6";

const Footer: React.FC = () => {
  return (
    <footer className="bg-primary-black text-accent-green py-8 font-mono border-t border-accent-green">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <h2 className="text-2xl font-bold glow-green-sm">HackAI_</h2>
            <p className="mt-2 text-secondary-green">
              Empowering_innovation_in_AI
            </p>
          </div>
          <div className="flex space-x-4">
            <a
              href="https://x.com/i/communities/1813691395217219590"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-secondary-green transition-colors duration-300"
            >
              <FaXTwitter size={24} />
            </a>
          </div>
        </div>
        <div className="mt-8 border-t border-secondary-green pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-secondary-green text-sm">
            &copy; {new Date().getFullYear()} HackAI. All_rights_reserved.
          </p>
          <nav className="mt-4 md:mt-0">
            <ul className="flex flex-wrap justify-center md:justify-end space-x-4">
              <li>
                <a
                  href="/privacy"
                  className="text-accent-green hover:text-secondary-green transition-colors duration-300 text-sm"
                >
                  Privacy_Policy
                </a>
              </li>
              <li>
                <a
                  href="/terms"
                  className="text-accent-green hover:text-secondary-green transition-colors duration-300 text-sm"
                >
                  Terms_of_Service
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="text-accent-green hover:text-secondary-green transition-colors duration-300 text-sm"
                >
                  Contact_Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
